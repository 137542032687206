import React from "react"
import { navigate } from 'gatsby'
import { Row, Col, Container } from "react-bootstrap"
import { Form, FormState, useAuth, SocialLogins } from "gatsby-theme-firebase"

import Layout from "../components/layout"

const IndexPage = () => {
  const { isAuthLoading, isLoggedIn, profile } = useAuth();

  return (
    <Layout pageInfo={{ pageName: "Team Communication" }}>
      <Container className="text-center">
      { !isAuthLoading && isLoggedIn ? ( 
        navigate('/admin')
       ) : (
        <Row >
          <Col >
            <FormState.Provider>
              <Form
                onLoginSuccess={user => {
                  navigate("/admin");
                }}
              />
            </FormState.Provider>
            <SocialLogins
              onSuccess={user => {
                navigate('/admin');
              }}
            />
          </Col>
        </Row>
      )}
      </Container>
    </Layout>
  )
}

export default IndexPage
